/* eslint-disable jsx-a11y/anchor-is-valid */

import classNames from 'classnames';

interface Tab<T> {
  label: string;
  value: T;
}

interface TabsProps<T> {
  tabs: Tab<T>[];
  selected: T;
  onChange: (index: T) => void;
}
const Tabs = <T extends string | number>({ tabs, selected, onChange }: TabsProps<T>) => {
  return (
    <div className="my-4 border-b-2 border-gray-200">
      <ul className="-mb-px flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
        {tabs.map((tab, index) => (
          <li className="mr-2" key={`tab-${index}`}>
            <a
              role="button"
              className={classNames(
                'group inline-flex border-b-2 border-transparent px-4 py-2 text-gray-800 visited:text-gray-800',
                {
                  'border-b-4 border-b-primary-500 !text-primary-500': tab.value === selected,
                },
              )}
              onClick={() => onChange(tab.value)}
            >
              {tab.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
