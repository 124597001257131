import { AssetAction } from '../types/types';
import { LAST_LOGIN_TIMESTAMP } from "../constants";
import dayjs from "dayjs";

export const upperCaseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const USDollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'code',
});

export const maskBarcode = (barcode: string) => {
  return barcode ? `${barcode.substring(0, 2)}*****${barcode.substring(barcode.length - 4)}` : '';
};

export const joinStrings = (stringsToJoin: Array<string | undefined>, joinChar: string = ' ') => {
  const array = stringsToJoin.filter(Boolean);
  return array.length > 0 ? array.join(joinChar) : undefined;
};

export const parseAssetAction = (action: AssetAction) => {
  switch (action) {
    case 'CHECKOUT':
      return 'Checked Out';
    case 'CHECKIN':
      return 'Checked In';
    case 'INVENTORY_SCAN':
      return 'Inventory Control Scan';
    case 'ASSET_STATUS_TO_AVAILABLE':
      return 'Status set: Available';
    case 'ASSET_STATUS_TO_CHECKED_OUT':
      return 'Status set: Checked Out';
    case 'ASSET_STATUS_TO_MAINTENANCE':
      return 'Status set: Out for Maintenance';
    case 'ASSET_STATUS_TO_DECOMMISSIONED':
      return 'Status set: Decommissioned';
    case 'ASSET_INVENTORY_STATUS_TO_IMPORTED':
      return 'Imported into the system';
    case 'ASSET_INVENTORY_STATUS_TO_COMPLIANT':
      return 'Inventory status: Compliant';
    case 'ASSET_INVENTORY_STATUS_TO_STALE':
      return 'Inventory status: Stale';
    default:
      return '';
  }
};

export const checkIfSessionExpired = () => {
  const lastActiveTimestamp = localStorage.getItem(LAST_LOGIN_TIMESTAMP);
  const currentTimestamp = dayjs().toISOString();
  return dayjs(currentTimestamp).diff(dayjs(lastActiveTimestamp), 'minute') >= 60;
}
