import { AppBar, Toolbar, Typography } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { titleFont, appBarStyles } from './styles';

export const OldHeader = () => {
  const { pageTitle } = useAppContext();

  return (
    <AppBar position="relative" sx={appBarStyles}>
      <Toolbar sx={{ width: '100%', alignItems: 'center', padding: 0 }}>
        <Typography variant="h1" component="div" sx={titleFont}>
          {pageTitle}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
