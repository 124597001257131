import { ReactNode, Suspense } from 'react';
import ChipButton from './ChipButton';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface PageScaffoldProps {
  title?: string;
  children: ReactNode;
  actions?: ReactNode;
  backTo?: string;
  backSteps?: number;
  limitWidth?: boolean;
  tabs?: ReactNode;
}

const PageScaffold = ({ title, children, actions, backTo, backSteps = -1, limitWidth, tabs }: PageScaffoldProps) => {
  const navigate = useNavigate();
  return (
    <Suspense
      fallback={
        <div className="grid h-screen w-full place-content-center bg-black">
          <p className="text-3xl text-white">Loading...</p>
        </div>
      }
    >
      <div className="flex h-screen w-full flex-col bg-gray-80 pt-10 lg:pt-0">
        <div className="flex flex-col border-b-gray-200 border-b-2 border-solid mb-6">
          <div className="flex flex-col justify-between overflow-x-scroll px-4 py-6 lg:flex-row lg:items-center lg:px-6">
            <div className="flex flex-col items-start">
              {backTo && <ChipButton showChevronBack text={backTo} onClick={() => navigate(backSteps)}></ChipButton>}
              <div className="self-center">{title && <Title title={title} />}</div>
            </div>
            <div className="flex flex-col gap-6 py-2 lg:flex-row lg:items-center">{actions}</div>
          </div>
          {tabs && <div className="px-3">{tabs}</div>}
        </div>
        <div
          className={classNames('flex w-screen flex-1 flex-col overflow-scroll px-4 lg:w-[calc(100vw-80px)]', {
            'mx-auto max-w-[1440px]': limitWidth,
          })}
        >
          {children}
        </div>
      </div>
    </Suspense>
  );
};

const Title = ({ title }: { title: string }) => {
  return <h1 className="self-start text-[2.5rem] font-bold text-primary-800">{title}</h1>;
};

export default PageScaffold;
