import { useQuery } from '@tanstack/react-query';
import { fetchAssetById } from '../api';
import { QUERY_KEYS } from '../constants';
import { Asset } from '../types/types';

interface UseAssetProps {
  assetId?: number;
  initialData?: Asset;
}
const useAsset = ({ assetId, initialData }: UseAssetProps) => {
  const {
    data: asset,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => fetchAssetById(assetId),
    queryKey: [QUERY_KEYS.ASSETS, { id: assetId }],
    enabled: !!assetId,
    initialData: initialData,
  });

  return { asset, isLoading, refetch };
};

export default useAsset;
