const PrivacyPolicy = () => {
  return (
    <div className="mx-auto max-w-[75ch] p-5 font-medium text-gray-600 md:p-10">
      <h1 className="text-2xl font-bold text-gray-800">Website Privacy Policy</h1>
      <span className="text-sm font-bold">Last modified: June 8, 2023</span>
      <br /> <br />
      <b>INTRODUCTION</b>
      <br />
      <p>
        Sleepy Owl Solutions, Inc. ("Company," "we," "us," "our"), a Colorado corporation, respects your privacy, and we
        are committed to protecting it through our compliance with this policy.
      </p>
      <p>
        This privacy policy ("Privacy Policy") describes the types of information we may collect from you or that you
        may provide when you visit the website: https://www.sleepyowl.tech/ or https://webapp.sleepyowl.tech/
        ("Website") and use the mobile application: The Owl Equipment app (the "App"), and our practices for collecting,
        using, maintaining, protecting, and disclosing that information.
      </p>
      <p>
        This Privacy Policy applies to information we collect:
        <ul>
          <li>On this Website.</li>
          <li>In email, text, and other electronic messages between you and this Website.</li>
          <li>When you use the App.</li>
        </ul>
      </p>
      <p>
        It does not apply to information collected by:
        <ul>
          <li>
            The Company, offline or through any other means, including on any other website operated by us or any third
            party.
          </li>
          <li>
            Any third party, including through any application or content (including advertising) that may link to or be
            accessible from or on the Website.
          </li>
        </ul>
      </p>
      <p>
        Please review this Privacy Policy and our Terms of Use prior to further use of the Website or our App. By using
        this Website or our App, you agree to the terms of this Privacy Policy and our Terms of Use. If you do not agree
        with our policies and practices, please do not use our Website or our App. If you still have any questions or
        concerns, please contact us at info@sleepyowl.tech.
      </p>
      <p>
        This Privacy Policy is expressly incorporated into our Terms of Use located at:
        https://webapp.sleepyowl.tech/terms
      </p>
      <p>
        This Privacy Policy may be updated or revised from time to time. We may do so, at any time and without notice
        (except to the extent required by law), by posting the revised version on this Website. We suggest that you
        review this Privacy Policy periodically for any such changes. Your continued use of this Website after we make
        changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for
        updates.
      </p>
      <br />
      <b>1. MINORS UNDER THE AGE OF 18</b>
      <p>
        Our Website and our App are not directed toward minors under eighteen (18) years of age. We do not knowingly
        collect personal information from minors under the age of 18. If you are under 18 years old, you may not Website
        or our App, and you may not provide any information to this Website or purchase or download our App through any
        online source. If we learn we have collected or received personal information from a minor under 18 years old,
        we will delete that information. If you believe we have any information from or about a minor under 18 years
        old, please contact us at: info@sleepyowl.tech.
      </p>
      <br />
      <b>2. INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</b>
      <p>
        We minimize the collection of personally identifiable information to the least amount required to complete a
        particular transaction. We currently do not collect any personal information directly from you when you visit
        our Website or install our App on your mobile device. We collect several types of information from and about
        users of our Website, including information:
        <ul>
          <li>
            About an individual that could reasonably be used to identify or contact that individual such as: your name,
            addresses, email address, and phone number ("personal information").
          </li>
          <li>
            That is about you but individually does not identify you, such as Internet Protocol (IP) address. If you
            access our Website or use our App from your mobile device, we may collect information about that devices
            such as a unique device identifier (UDID), mobile phone number, and details about your mobile carrier.
          </li>
          <li>
            That is non-personally identifiable including: data about your internet connection, the identity of your
            Internet Service Provider (ISP), the name and version of your operating system, the name and version of your
            web browser, the "referring" site from which you initially arrived at our Website, the date and time of your
            visit, and the pages you visit on our Website.
          </li>
        </ul>
      </p>
      <p>
        We collect this information:
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the Website or use the App. Information collected automatically may
            include usage details, IP addresses, and information collected through cookies and other tracking
            technologies.
          </li>
          <li>
            From third parties, for example, our business partners or third-party sign-on partners (such as Google or
            Facebook).
          </li>
        </ul>
      </p>
      a. Information We Collect Through Automatic Data Collection Technologies
      <p>
        As you navigate through and interact with the Website or us the App, we may use automatic data collection
        technologies to collect certain information about your equipment, browsing actions, and patterns, including:
        <ul>
          <li>
            Details of your visits to the Website, including: traffic data, location data, logs, and other communication
            data and the resources that you access and use on the Website.
          </li>
          <li>
            Information about your computer or mobile device and internet connection, including your IP address,
            operating system, and browser type.
          </li>
        </ul>
        <br />
        The information we collect automatically is statistical data and does not include personal information. It helps
        us to improve the Website and to deliver a better and more personalized service, including by enabling us to:
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize the Website according to your individual
            interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to the Website.</li>
        </ul>
      </p>
      b. The technologies we use for this automatic data collection may include Cookies (or browser cookies)
      <br />
      <p>We use different types of cookies for different reasons. Our cookies policy is further explained below.</p>
      <br />
      <b>3. COOKIES POLICY</b>
      <br />
      <p>
        We use cookies and similar automatic tracking technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and how you can refuse certain cookies is
        set out in this section.
      </p>
      a. About cookies
      <br />
      <p>
        A cookie is a small file placed on the hard drive of your device. Cookies are used to "remember" you, to provide
        you with content relevant to your interests, for security reasons, and to help us understand how our Website and
        App are being used.
      </p>
      b. Types of Cookies We Use
      <p>We do not currently use cookies and will update this Privacy Policy if we use cookies in the future.</p>
      c. Our Website may also contain cookies set by third parties
      <p>
        Some content or applications on the Website or in the App may be served by third parties, including advertisers,
        content providers, and application providers. These third parties may use cookies alone or in conjunction with
        web beacons or other tracking technologies to collect information about you when you use the Website or App. The
        information they collect may be associated with your personal information or they may collect information,
        including personal information, about your online activities over time and across different websites and other
        online services. They may use this information to provide you with interest-based (behavioral) advertising or
        other targeted content. We are not responsible for any third-party tracking of your web browsing.
      </p>
      <p>
        We use the following third-party cookies and web service providers:
        <ul>
          <li>
            We use Amazon Web Services as our hosting platform. Their privacy policy is located here:
            https://aws.amazon.com/privacy/.
          </li>
          <li>Our App may be downloaded from Apple's App Store or Google Play.</li>
          <li>https://www.apple.com/legal/privacy/data/en/app-store/</li>
          <li>https://policies.google.com/privacy</li>
        </ul>
      </p>
      <p>
        We do not control these third parties' tracking technologies or how they may be used. If you have any questions
        about an advertisement or other targeted content, you should contact the responsible provider directly. For
        information about how you can opt out of receiving targeted advertising from many providers, see "Choices About
        How We Use and Disclose Your Information."
      </p>
      <p>
        We do not use tracking technologies to collect information about your online activities over time or across
        third-party websites or other online services (behavioral tracking). However, third-party sites that link to our
        Website (such as Google, LinkedIn, or Facebook) may engage in behavioral tracking.
      </p>
      d. How You Can Control Cookies
      <p>
        Most browsers allow you to control cookies, including whether or not to accept them and how to remove them. You
        may refuse to accept browser cookies by activating the appropriate setting on your device's browser. However, if
        you select this setting, you may be unable to access certain parts of the Website. Unless you have adjusted your
        browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to
        the Website. You can learn more about cookies and how to manage them here:
        https://www.aboutcookies.org/how-to-manage-and-delete-cookies.
      </p>
      <p>
        To opt-out of interest-based advertising by advertisers on our Services (if applicable), visit
        http://www.aboutads.info/choices/.
      </p>
      <br />
      <b>4. OUR "DO NOT TRACK" POLICY</b>
      <p>
        Some web browsers offer a "Do Not Track" option, which sends a signal to a website regarding that user's
        preference regarding tracking. Currently, there is no "Do Not Track" technology standard for how any company
        abides by a "Do Not Track" signal received from a user's web browser.
        <br />
        Our Website does NOT respond to "Do Not Track" signals from your web browser.
      </p>
      <br />
      <b>5. HOW WE USE YOUR INFORMATION</b>
      <br />
      <p>We do NOT sell user personal information.</p>
      <p>
        We use information that we collect about you or that you provide to us, including any personal information:
        <ul>
          <li>To present the Website and its contents to you.</li>
          <li>To provide you with information, products, or services that you request from us.</li>
          <li>For email communications.</li>
          <li>To complete transactions that you initiate with us.</li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any contracts entered into between you and
            us, including for billing and collection.
          </li>
          <li>To notify you about changes to the Website or any products or services we offer or provide though it.</li>
          <li>To allow you to access and participate in interactive features on the Website.</li>
          <li>In any other way we may describe when you provide the information.</li>
          <li>For any other purpose with your consent.</li>
        </ul>
      </p>
      <br />
      <b>6. RETENTION POLICY</b>
      <br />
      <p>
        The time periods for which we retain information we collect depend on the purposes for which we use it. The
        Company will keep your information for as long as you are a registered subscriber or user of our Website or for
        as long as we have a business purpose to do so and, thereafter, for no longer than is required or permitted by
        law.
      </p>
      <br />
      <b>7. DISCLOSURE OF YOUR INFORMATION</b>
      <br />
      <p>
        We may disclose aggregated information about our users, and information that does not identify any individual,
        without restriction.
      </p>
      <p>
        We may disclose personal information that we collect, or you provide as described in this Privacy Policy:
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>To contractors, service providers, and other third parties we use to support our business.</li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part
            of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our
            Website users is among the assets transferred.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>For any other purpose disclosed by us when you provide the information.</li>
          <li>With your consent.</li>
        </ul>
        We may also disclose your personal information:
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to respond to any government or regulatory
            request.
          </li>
          <li>
            To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
            Company, our customers, or others.
          </li>
        </ul>
      </p>
      <br />
      <b>8. CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</b>
      <br />
      <p>
        We strive to provide you with choices regarding the personal information you provide to us. We have created
        mechanisms to provide you with the following control over your information:
        <ul>
          <li>
            Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to
            alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this
            Website may then be inaccessible or not function properly.
          </li>
          <li>
            Third-Party Tracking. We do not control third parties' collection or use of your information to serve
            interest-based advertising. However, these third parties may provide you with ways to choose not to have
            your information collected or used in this way.
          </li>
        </ul>
      </p>
      <br />
      <b>9. ACCESSING AND CORRECTING YOUR INFORMATION</b>
      <br />
      <p>
        You may request updating, correction or deletion of any personal information you provided to Sleepy Owl
        Solutions, Inc., by contacting us directly. We may not accommodate a request to change information if we believe
        the change would violate any law or legal requirement or cause the information to be incorrect.
      </p>
      <br />
      <b>10. DATA SECURITY</b>
      <br />
      <p>
        We have implemented commercially reasonable measures designed to secure your personal information from
        accidental loss and from unauthorized access, use, alteration, and disclosure, including regular encrypted
        backups, limiting access to our Website's control panel and databases, two-factor authentication to access our
        website's control panel and databases, SSL technologies and HTTPS. All information you provide to us is stored
        on secure servers behind firewalls. Our web hosting platform has additional safeguards in place.
      </p>
      <p>
        The safety and security of your information also depends on you. You can help protect your computer or mobile
        device by keeping it up to date/patched as well as running antivirus programs to protect your online browsing.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not completely secure. Although we do our
        best to protect your personal information, we cannot guarantee the security of your personal information
        transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible
        for circumvention of any privacy settings or security measures contained on the Website.
      </p>
      <br />
      <b>11. NOTICE TO CALIFORNIA USERS</b>
      <br />
      <p>
        This Supplemental California Privacy Notice only applies to our processing of personal information that is
        subject to the California Consumer Privacy Act of 2018 ("CCPA"). The CCPA specifies that natural persons who are
        California residents have the right to know what categories of personal information the Company, has collected
        about them and whether we have disclosed or sold that personal information for a business purpose (e.g., to a
        service provider) in the preceding twelve (12) months.
      </p>
      <p>
        For purposes of the CCPA, we do not "sell" (as that term is defined in the CCPA) personal information, nor do we
        have actual knowledge of any "sale" of personal information of minors under sixteen (16) years of age.
      </p>
      <p>Categories of personal information we may collect about you:</p>
      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
          </tr>
        </thead>
        <tr>
          <td>A. Identifiers</td>
          <td>
            Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
            personal identifier, online identifier, Internet Protocol address, email address, and account name
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>B. Personal information categories listed in the California Customer Records statute</td>
          <td>
            Social Security number, passport number, driver's license or state identification card number, insurance
            policy number, employment, employment history, financial information, medical information, or health
            insurance information
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>C. Protected classification characteristics under California or federal law</td>
          <td>Gender and date of birth</td>
          <td>NO</td>
        </tr>
        <br />
        <tr>
          <td>D. Commercial information</td>
          <td>Transaction information, purchase history, financial details, and payment information</td>
          <td>NO</td>
        </tr>
        <br />
        <tr>
          <td>E. Biometric information</td>
          <td>Fingerprints and voiceprints</td>
          <td>NO</td>
        </tr>
        <br />
        <tr>
          <td>F. Internet or other similar network activity</td>
          <td>
            Browsing history, search history, online behavior, interest data, and interactions with our and other
            websites, applications, systems, and advertisements
          </td>
          <td>YES</td>
        </tr>
        <br />
        <tr>
          <td>G. Geolocation data</td>
          <td>Device location</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
          <td>Images and audio, video or call recordings created in connection with our business activities</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>I. Professional or employment-related information</td>
          <td>
            Business contact details in order to provide you our services at a business level or job title, work
            history, and professional qualifications if you apply for a job with us
          </td>
          <td>NO</td>
        </tr>
        <br />
        <tr>
          <td>J. Education Information</td>
          <td>Student records and directory information</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>K. Inferences drawn from other personal information</td>
          <td>
            Inferences drawn from any of the collected personal information listed above to create a profile or summary
            about, for example, an individual's preferences and characteristics
          </td>
          <td>NO</td>
        </tr>
      </table>
      <p>
        We may also collect other personal information outside of these categories instances where you interact with us
        in person, online, or by phone or mail in the context of:
        <br /> <br />• Receiving help through our customer support channels;
        <br />• Participation in customer surveys; and
        <br />• Facilitation in the delivery of our Services and to respond to your inquiries.
        <br /> <br />
        You have the right not to receive discriminatory treatment from us for exercising the privacy rights granted by
        the CCPA.
      </p>
      <br />
      <b>12. VERIFIABLE CONSUMER REQUESTS</b>
      <br />
      <p>
        Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to
        your personal information. You may also make a verifiable consumer request on behalf of your minor child. In
        order to verify your request, we will ask you to provide your name, email address, and certain other pieces of
        identifying information. Once you have submitted this information and any necessary supporting documentation, we
        will confirm the information by reviewing it against our records. To designate an authorized agent, please
        contact us as set forth below.
      </p>
      <br />
      <b>13. CHANGES TO OUR PRIVACY POLICY</b>
      <br />
      <p>
        It is our policy to post any changes we make to our Privacy Policy on this page with a notice that the Privacy
        Policy has been updated on the Website home page. If we make material changes to how we treat our users'
        personal information, we will notify you by email to the specified in your account and through a notice on the
        Website home page. The date the Privacy Policy was last revised is identified at the top of the page. You are
        responsible for ensuring we have an up-to-date active and deliverable email address for you, and for
        periodically visiting our Website and this Privacy Policy to check for any changes. By continuing to access or
        use the Site or the Services after those changes become effective, you agree to be bound by the revised policy.
      </p>
      <br />
      <b>14. DISCLAIMER</b>
      <br />
      <p>
        The Website contains links to other websites. When you link to another website, you leave our Website, which
        means that this Privacy Policy will no longer apply. When you link to another website, you are subject to the
        privacy policy of that website.
      </p>
      <br />
      <b>15. CONTACT INFORMATION</b>
      <br />
      <p>
        To ask questions or comment about this Privacy Policy and our privacy practices or to exercise your rights as
        described in this Privacy Policy, please contact us at: info@sleepyowl.tech.
      </p>
      <br /> <br />
      Notices may be sent to:
      <br />
      Sleepy Owl Solutions, Inc.
      <br />
      ATTN: Basecamp Legal
      <br />
      3900 E. Mexico Ave, Ste 300
      <br />
      Denver, CO 80210
    </div>
  );
};

export default PrivacyPolicy;
