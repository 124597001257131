import classNames from 'classnames';
import { InputHTMLAttributes, Ref, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const TextInput = ({ error, ...props }: TextInputProps, ref: Ref<HTMLInputElement>) => {
  return (
    <div className="flex flex-col gap-1">
      <input
        ref={ref}
        {...props}
        className={twMerge(
          classNames(
            'rounded border border-gray-100 bg-gray-80 px-2 py-1 text-right',
            {
              'border-red-500': !!error,
            },
            props.className,
          ),
        )}
      />
      {error && <div className="text-xs text-red-500">{error}</div>}
    </div>
  );
};

export default forwardRef(TextInput);
