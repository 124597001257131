import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

const modalContentEl = document.getElementById('modal-root');

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  closeOnClickOutside?: boolean;
  scrollable?: boolean;
  contentPadding?: boolean;
}

const Modal = ({
  children,
  open,
  onClose,
  showCloseButton = true,
  closeOnClickOutside = false,
  scrollable = true,
  contentPadding = true,
}: ModalProps) => {
  const modalContentRef = useRef(null);

  useOnClickOutside(modalContentRef, () => {
    if (closeOnClickOutside) onClose();
  });

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  if (!modalContentEl || !open) return null;
  return (
    <>
      {createPortal(
        <div className="relative !z-100" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-black/60 bg-opacity-75 backdrop-blur transition-opacity" />

          <div className="fixed inset-0 !z-100 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <div
                className={twMerge(
                  classNames(
                    'min-w-2xl relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:m-8 md:w-auto',
                    {
                      'max-h-[calc(100vh_-_64px)]': !scrollable,
                    },
                  ),
                )}
              >
                <div
                  className={twMerge(
                    classNames('relative bg-white p-5 md:p-10', {
                      'p-0 md:p-0': !contentPadding,
                    }),
                  )}
                  ref={modalContentRef}
                >
                  {showCloseButton && (
                    <div className="absolute right-3 top-3 cursor-pointer" onClick={onClose}>
                      <Close />
                    </div>
                  )}
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>,
        modalContentEl,
      )}
    </>
  );
};

export default Modal;
