import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: 24,
      fontWeight: 700,
      color: '#001E57',
    },
    dataLabel: {
      fontSize: 14,
      fontWeight: 700,
      color: '#001E57',
    },
    dataValue: {
      fontSize: 14,
      fontWeight: 400,
      color: '#323232',
    },
    subtitle1: {
      fontSize: 15,
    },
    fontFamily: [
      'Inter',
      '"Helvetica Neue"',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    type: 'light',
    mode: 'light',
    primary: {
      main: '#0058FF',
    },
    secondary: {
      main: '#f50057',
    },
    neutral: {
      main: '#eaeaea',
      contrastText: '#333333',
      light: '#eaeaea',
      medium: '#cccccc',
      dark: '#333333',
    },
    foundation: {
      white: '#ffffff',
      blue90: {
        main: '#001E57',
        contrastText: '#ffffff',
      },
      blue10: '#001E57',
    },
    gray: {
      80: '#FAFAFA',
      100: '#D9D9D9',
      200: '#CED1D6',
      400: '#999999',
      500: '#7A7E85',
      600: '#5E5E5E',
      800: '#323232',
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          borderRadius: '4px',
          fontWeight: 700,
          padding: '10px 22px',
          color: '#0058FF',
          '&:hover': {
            backgroundColor: '#0058FF',
            color: 'white',
          },
        },
        primary: {
          backgroundColor: '#0058FF',
          border: '2px solid #0058FF',
          color: 'white',
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: '#cacaca',
            border: '2px solid #cacaca',
          },
          '&:hover': {
            backgroundColor: '#397dfc',
            borderColor: '#397dfc',
          },
        },
        outlined: {
          border: '2px solid #0058FF',
          '&.Mui-disabled': {
            border: '2px solid #cacaca',
          },
          '&:hover': {
            border: '2px solid #0058FF',
          },
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            height: '48px',
            textTransform: 'uppercase',
            backgroundColor: '#0058FF',
            borderRadius: '8px',
            fontSize: 15,
            fontWeight: 500,
            color: 'white',
            '&:hover': {
              backgroundColor: '#0058FF',
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 400,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 700,
          color: '#001e57',
        },
      },
    },
    MuiTextField: {
      '.MuiFormLabel-root': {
        // color: "red"
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          // color: "#cc0000",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 22,
          fontWeight: 500,
          color: '#001E57',
        },
      },
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        fontSize: 100,
      },
    },
    MuiDataGrid: {
      root: {
        style: {
          border: 'none',
        },
        border: 'none',
      },
    },
  },
});
