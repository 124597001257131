import { z } from 'zod';
import { upperCaseFirstLetter } from '../utils/helpers';

const requiredMessage = (fieldName: string) => `${upperCaseFirstLetter(fieldName)} is required`;

export const loginSchema = z.object({
  email: z
    .string()
    .nonempty({
      message: requiredMessage('email'),
    })
    .email({
      message: 'Email is invalid',
    }),
  password: z
    .string()
    .nonempty({ message: requiredMessage('password') })
    .min(8, { message: 'Password must be at least 8 characters long' }),
});
export type LoginSchema = z.infer<typeof loginSchema>;

export const resetPasswordSchema = z.object({
  email: z
    .string()
    .nonempty({
      message: requiredMessage('email'),
    })
    .email({
      message: 'Email is invalid',
    }),
});
export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export const assetSchema = z.object({
  assetId: z.number(),
  accountId: z.number(),
  name: z.string().nonempty(),
  stockNumber: z.string(),
  serialNumber: z.string(),
  barcode: z.string(),
  assetType: z.string(),
  description: z.string(),
  quantity: z.number(),
  acquisitionCost: z.number().optional(),
  acquisitionDate: z.string().nullable(),
  building: z.string(),
  location: z.string(),
  pilferable: z.enum(['YES', 'NO']),
  maintenance: z.boolean(),
  maintenanceNote: z.string().nullable(),
  manufacturerName: z.string(),
  manufacturerPartNumber: z.string(),
  manufacturerModelNumber: z.string(),
  manufactureYear: z.number().optional(),
  externalAssetId: z.string(),
  missing: z.boolean(),
});
export type AssetSchema = z.infer<typeof assetSchema>;

export const signUpSchema = z.object({
  firstName: z.string().nonempty({
    message: requiredMessage('first name'),
  }),
  lastName: z.string().nonempty({
    message: requiredMessage('last name'),
  }),
  email: z
    .string()
    .nonempty({
      message: requiredMessage('email'),
    })
    .email({
      message: 'Email is invalid',
    }),
  organization: z.string().nonempty({
    message: requiredMessage('organization'),
  }),
  termsAndConditions: z.boolean().refine((val) => val, {
    message: 'You must accept the terms & conditions',
  }),
});
export type SignUpSchema = z.infer<typeof signUpSchema>;
