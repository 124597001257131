import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Auth } from 'aws-amplify';
import { ComponentType, SVGProps, useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import owl from '../../assets/images/owl.png';
import { ReactComponent as PhoneInTalk } from '../../assets/icons/phone-in-talk.svg';
import { ReactComponent as Training } from '../../assets/icons/training.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { ReactComponent as ScaleBalance } from '../../assets/icons/scale-balance.svg';
import { ReactComponent as HelpOutline } from '../../assets/icons/help-outline.svg';

import { useSideNavigation } from '../../contexts/SideNavigationContext';
import routes from '../../constants/routes';
import { useUserContext } from '../../contexts/UserContext';
import { TOKEN_STORAGE, USER_STORAGE } from '../../constants';

import Navigation from './Navigation';

const SideNavigation = () => {
  const { isExpanded, setIsExpanded } = useSideNavigation();
  const user = useUserContext();
  const navigate = useNavigate();

  const [userPopoverOpen, setUserPopoverOpen] = useState(false);

  const { firstName, lastName } = user?.userInfo?.user || {};
  const fullName = `${firstName} ${lastName}`;
  const initials = `${firstName?.[0]}${lastName?.[0]}`;
  
  const onOpenPopoverContent = () => {
    setUserPopoverOpen(true);
  }
  
  const onClosePopoverContent = () => {
    setUserPopoverOpen(false);
  }

  return (
    <>
      <div
        className={classNames(
          'min-w-screen absolute inset-0 !z-40 block min-h-[100dvh] bg-black/50 transition-all lg:invisible',
          {
            'opacity-100': isExpanded,
            'pointer-events-none opacity-0': !isExpanded,
          },
        )}
        onClick={() => {
          setIsExpanded(false);
        }}
      />
      <aside
        className={classNames(
          'fixed bottom-0 left-0 top-0 z-50 flex h-[100dvh] flex-col justify-between gap-6 bg-white p-4 drop-shadow-xl duration-200',
          {
            'w-20 -translate-x-full items-center lg:flex lg:translate-x-0': !isExpanded,
            'w-48 items-start': isExpanded,
          },
        )}
        onMouseEnter={() => {
          setIsExpanded(true);
        }}
        onMouseLeave={() => {
          setIsExpanded(false);
        }}
      >
        <img
          src={owl}
          alt="logo"
          role="link"
          className="mx-auto w-8 cursor-pointer object-contain py-6"
          onClick={() => {
            navigate(routes.dashboard);
          }}
        />

        <Navigation />

        <div
          className="group relative flex w-full cursor-pointer select-none flex-row items-center gap-x-2"
          onClick={onOpenPopoverContent}
        >
          <div className="min-w-[48px]">
            <div className="mx-auto grid h-8 w-8 place-content-center rounded-full bg-primary-800 text-sm font-bold text-white group-hover:bg-primary-500">
              {initials}
            </div>
          </div>
          <AnimatePresence>
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-primary-800 group-hover:text-primary-500"
              >
                {fullName}
              </motion.span>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {userPopoverOpen && (
              <PopoverContent initials={initials} fullName={fullName} onClose={onClosePopoverContent} />
            )}
          </AnimatePresence>
        </div>
      </aside>
    </>
  );
};

interface PopoverContentProps {
  initials: string;
  fullName: string;
  onClose: () => void;
}
const PopoverContent = ({ initials, fullName, onClose }: PopoverContentProps) => {
  const popoverRef = useRef(null);

  const user = useUserContext();
  const navigate = useNavigate();
  useOnClickOutside(popoverRef, () => {
    onClose();
  });

  const role = user.userInfo?.user?.role.type;
  const unit = user.userInfo?.user?.organization.name;
  const email = user.userInfo?.user?.email;

  const handleSignOut = useCallback(async () => {
    try {
      Auth.signOut();
      sessionStorage.removeItem(USER_STORAGE);
      localStorage.removeItem(TOKEN_STORAGE);
      user.setOrgAccounts([]);
      navigate(routes.login, { replace: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }, [navigate]);
  
  const PopoverLink = ({
    to,
    IconComponent,
    label,
  }: {
    to: string;
    IconComponent: ComponentType<SVGProps<SVGSVGElement>>;
    label: string;
  }) => {
    const onNavigate = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      navigate(to);
      onClose();
    }
    
    return (
      <button 
        onClick={onNavigate}
        className="cursor-pointer font-normal items-center text-sm !text-primary-800 flex space-x-3 hover:!text-primary-500 [&>svg]:hover:fill-primary-500">
        <IconComponent
          className="fill-primary-800 w-3 h-3"  
        />
        <span>{label}</span>
      </button>
    );
  }

  return (
    <motion.div
      ref={popoverRef}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ ease: 'easeOut', duration: 0.1 }}
      className="absolute bottom-0 left-0 z-100 cursor-default rounded-md border border-gray-200 bg-white drop-shadow-xl"
    >
      <div className="flex flex-col">
        <span className="font-bold text-sm text-primary-800 ml-2 mt-2">Helpful links</span>
        <div className="flex flex-col gap-y-3 p-3 items-start overflow-hidden overflow-y-scroll w-full text-sm text-primary-800">
          <PopoverLink
            to={routes.contact}  
            IconComponent={PhoneInTalk}
            label="Contact Us"
          />
          <PopoverLink
            to={routes.legal}
            IconComponent={ScaleBalance}
            label="Legal"
          />
          <PopoverLink
            to={routes.tutorials}
            IconComponent={Training}
            label="Tutorials"
          />
          <PopoverLink
            to={routes.releaseNotes}
            IconComponent={HelpOutline}
            label="Release Notes"
          />
        </div>

        <div className="h-[1px] w-full bg-gray-200" />
        
        <div className="flex flex-col gap-y-3 p-3">
          <div>
            <span className="text-md text-primary-800">
              {role} | {unit}
            </span>
          </div>
          <div className="flex gap-3">
            <div className=" mx-auto grid h-8 w-8 place-content-center rounded-full bg-primary-800 text-sm font-bold text-white">
              {initials}
            </div>
            <div className="flex flex-col justify-between text-start">
              <span className="text-sm text-primary-800">{fullName}</span>
              <span className="text-xs font-normal text-primary-800/50">{email}</span>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-full bg-gray-200" />

        <div
          className="m-1 flex cursor-pointer items-center gap-x-2 rounded-sm bg-error-500/20 p-2 hover:bg-error-500/[25%]"
          onClick={handleSignOut}
        >
          <Logout />
          <span className="text-sm font-normal text-error-500">Log out</span>
        </div>
      </div>
    </motion.div>
  );
};

export default SideNavigation;
