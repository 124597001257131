import { getHeaders, host } from '.';

export const fetchAllRoles = async () => {
  const headers = await getHeaders();

  try {
    return fetch(host + `/roles`, {
      headers,
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((e) => console.log('Error fetching roles: ', e));
  } catch (e) {
    console.log('Error fetching roles: ', e);
  }
};
