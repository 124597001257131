import React, { useMemo, useState } from 'react';
import PageScaffold from '../../components/PageScaffold';
import VideoCard from './components/VideoCard';
import Modal from '../../components/Modal';
import { useQuery } from '@tanstack/react-query';
import { trainingVideos } from '../../api/website';
import { QUERY_KEYS } from '../../constants';
import VideoPlayer from './components/VideoPlayer';

const Tutorials = () => {
  const [selectedVideoId, setSelectedVideoId] = useState<number | undefined>();

  const { data: webVideos } = useQuery({
    queryFn: () => trainingVideos('web'),
    queryKey: [QUERY_KEYS.TRAINING_VIDEOS_WEB],  
    refetchOnWindowFocus: false,
  });

  const { data: mobileVideos } = useQuery({
    queryFn: () => trainingVideos('mobile'),
    queryKey: [QUERY_KEYS.TRAINING_VIDEOS_MOBILE],
    refetchOnWindowFocus: false,
  });

  const selectedVideo = useMemo(
    () =>
      [...(webVideos?.content || []), ...(mobileVideos?.content || [])]?.find(
        (video) => video.videoId === selectedVideoId,
      ),
    [mobileVideos?.content, selectedVideoId, webVideos?.content],
  );

  return (
    <>
      <PageScaffold title="Tutorials" limitWidth>
        {webVideos?.content && webVideos.content.length > 0 && (
          <>
            <h2 className="mb-2 text-3xl font-semibold text-primary-800">Web</h2>
            <div className="grid grid-cols-1 justify-items-center gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
              {webVideos?.content?.map((video) => (
                <VideoCard
                  key={video.videoId}
                  title={video.name}
                  duration={`${video.videoLengthSeconds.toString()} seconds video`}
                  onClick={() => {
                    setSelectedVideoId(video.videoId);
                  }}
                />
              ))}
            </div>
          </>
        )}
        <div className="mt-10" />
        {mobileVideos?.content && mobileVideos.content.length > 0 && (
          <>
            <h2 className="mb-2 text-3xl font-semibold text-primary-800">Mobile</h2>
            <div className="grid grid-cols-1 justify-items-center gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
              {mobileVideos?.content?.map((video) => (
                <VideoCard
                  key={video.videoId}
                  title={video.name}
                  duration={`${video.videoLengthSeconds.toString()} seconds video`}
                  onClick={() => {
                    setSelectedVideoId(video.videoId);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </PageScaffold>
      <Modal
        open={!!selectedVideoId}
        onClose={() => {
          setSelectedVideoId(undefined);
        }}
        closeOnClickOutside
        contentPadding={false}
      >
        <VideoPlayer video={selectedVideo} />
      </Modal>
    </>
  );
};

export default Tutorials;
