import { appBarHeight } from '../../constants';

export const appBarStyles = {
  bgcolor: '#FAFAFA',
  borderBottom: `1px solid`,
  borderColor: 'foundation.blue10',
  boxShadow: 'none',
  flex: 0,
  flexDirection: 'row',
  minHeight: `${appBarHeight}px`,
  padding: '0',
};

export const titleFont = {
  color: 'foundation.blue90.main',
  flexGrow: 1,
  fontSize: 32,
  fontWeight: 500,
  letterSpacing: '0',
  textTransform: 'capitalize',
};

export const userNameFont = {
  color: 'foundation.blue90.main',
  flexGrow: 0,
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: '0',
  marginLeft: 'auto',
  marginRight: '0',
  textTransform: 'initial',
};
