// TODO add error toasts

import { getHeaders, host } from '.';
import { Asset, AssetHistory, Checkout, Paginated, UpdateAsset } from '../types/types';
import api from './api';

export const fetchAssetById = async (id?: number) => {
  if (!id) return;
  try {
    const { data } = await api.get<Asset>(`/assets/${id}`);
    return data;
  } catch (e) {
    console.log('Error occurred fetching assets: ', e);
  }
};

export const fetchAssetsByAccountId = async (id = 1, rows: any, page: any) => {
  const headers = await getHeaders();

  try {
    return fetch(host + `/accounts/${id}/assets?page=${page}&size=${rows}`, {
      headers,
      method: 'GET',
    })
      .then((res) => res.json())
      .catch((e) => console.log('Error fetching assets: ', e));
  } catch (e) {
    console.log('Error fetching assets: ', e);
  }
};

export const fetchBulkTemplate = async () => {
  const headers = await getHeaders();
  headers.delete('Content-Type');

  try {
    return fetch(host + `/assets/bulk/template`, {
      headers,
      method: 'GET',
    })
      .then((data) => data)
      .catch((e) => console.log('Error fetching template: ', e));
  } catch (e) {
    console.log('Error fetching template: ', e);
  }
};

export const bulkAssetsUpload = async (accountId: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await api.post(`/accounts/${accountId}/assets/bulk`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

type FetchAsset = {
  accountIds: number[];
  page?: number;
  size?: number;
  status?: string;
  sort?: string;
  assetName?: string;
  maintenanceNoteFilter?: string;
  assetTypeFilter?: string;
  buildingFilter?: string;
  locationFilter?: string;
  manufacturerNameFilter?: string;
  nameFilter?: string;
  isMaintenance?: boolean;
  isMissing?: boolean;
  isPilferable?: boolean;
};
export const fetchAssets = async ({ 
  accountIds, 
  page, 
  size, 
  status, 
  sort, 
  assetName, 
  maintenanceNoteFilter,
  assetTypeFilter,
  buildingFilter,
  locationFilter,
  manufacturerNameFilter,
  nameFilter,
  isMaintenance,
  isMissing,
  isPilferable,
}: FetchAsset) => {
  const searchParams = new URLSearchParams();
  searchParams.append('accountIds', accountIds.join(','));
  if (nameFilter) {
    searchParams.append('assetName', nameFilter);
  }
  else if (assetName) {
    searchParams.append('assetName', assetName);
  }
  
  if (page) {
    searchParams.append('page', page.toString());
  }
  
  if (size) {
    searchParams.append('size', size.toString());
  }
  
  if (status && status.split('=').length === 2) {
    searchParams.append(status.split('=')[0], status.split('=')[1]);
  }
  
  if (sort) {
    searchParams.append('sort', sort);
  }
  
  if (maintenanceNoteFilter === 'isEmpty') {
    searchParams.append('hasMaintenanceNote', 'false');
  } else if (maintenanceNoteFilter === 'isNotEmpty') {
    searchParams.append('hasMaintenanceNote', 'true');
  }
  
  if (assetTypeFilter) {
    searchParams.append('assetType', assetTypeFilter);
  }
  
  if (buildingFilter) {
    searchParams.append('buildingName', buildingFilter);
  }
  
  if (locationFilter) {
    searchParams.append('roomName', locationFilter);
  }
  
  if (manufacturerNameFilter) {
    searchParams.append('manufacturerName', manufacturerNameFilter);
  }
  
  if (isMaintenance) {
    searchParams.append('maintenance', 'true');
  }
  
  if (isMissing) {
    searchParams.append('missing', 'true');
  }
  
  if (isPilferable) {
    searchParams.append('pilferable', 'YES');
  }

  try {
    const res = await api.get(`/assets/filters?${searchParams.toString()}`);
    return res.data;
  } catch (e) {
    console.log('Error occurred fetching assets: ', e);
  }
};

export const updateAsset = async (id: number, data: UpdateAsset) => {
  try {
    const res = await api.put(`/assets/${id}`, data);
    return res.data;
  } catch (e) {
    console.log('Error occurred updating asset: ', e);
  }
};

interface MaintenancePayload {
  flag: boolean;
  note: string | null;
}
export const updateAssetMaintenance = async (id: number, data: MaintenancePayload) => {
  try {
    const res = await api.put(`/assets/${id}/maintenance`, data);
    return res.data;
  } catch (e) {
    console.log('Error occurred updating asset maintenance: ', e);
  }
};

export const uploadImage = async (id: number, image: File) => {
  const formData = new FormData();
  formData.append('file', image);
  try {
    const res = await api.put(`/assets/${id}/pictures`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (e) {
    console.log('Error occurred uploading image: ', e);
  }
};

export const deleteImage = async (id: number) => {
  try {
    const res = await api.delete(`/assets/${id}/pictures`);
    return res.data;
  } catch (e) {
    console.log('Error occurred deleting image: ', e);
  }
};

export const getHistory = async (id?: number): Promise<Paginated<AssetHistory> | undefined> => {
  if (!id) return;
  try {
    const res = await api.get(`/assets/${id}/history`);
    return res.data;
  } catch (e) {
    console.log('Error occurred getting history: ', e);
  }
};

export const getCheckout = async (id?: number): Promise<Checkout | undefined> => {
  if (!id) return;
  try {
    const res = await api.get(`/checkouts/${id}`);
    return res.data;
  } catch (e) {
    console.log('Error occurred getting checkout: ', e);
  }
};

export const getCheckoutForm = async (id?: number): Promise<{ url: string } | undefined> => {
  if (!id) return;
  try {
    const res = await api.get(`/checkouts/${id}/form`);
    return res.data;
  } catch (e) {
    console.log('Error occurred getting checkout form: ', e);
  }
};

export const updateAssetStatus = async (id?: number, status?: string) => {
  if (!id || !status) return;
  try {
    const res = await api.patch(`/assets/${id}/status/${status}`);
    return res.data;
  } catch (e) {
    console.log('Error occurred updating asset status: ', e);
  }
};

export const markAsMissing = async (id?: number, missing: boolean = false) => {
  if (!id) return;
  try {
    const res = await api.patch(`/assets/${id}/missing`, missing);
    return res.data;
  } catch (e) {
    console.log('Error occurred marking asset as missing: ', e);
  }
};
