import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Layout as MainLayout } from './layouts/Main';
import { Container } from '@mui/system';
import AuthLayout from './layouts/AuthLayout';
import routes from './constants/routes';
import { fetchAssetById } from './api';
import AssetDetailEdit from './containers/AssetDetail/edit';
import Tutorials from './containers/Tutorials';

const Accounts = React.lazy(() => import('./containers/Accounts'));
const AddUser = React.lazy(() => import('./containers/Users/AddUser'));
const Assets = React.lazy(() => import('./containers/Assets/index'));
const Contact = React.lazy(() => import('./containers/Contact'));
const Docs = React.lazy(() => import('./containers/Docs'));
const Dashboard = React.lazy(() => import('./containers/Dashboard'));
const Inventory = React.lazy(() => import('./containers/Inventory'));
const InventoryDetail = React.lazy(() => import('./containers/Inventory/inventoryDetail'));
const Locations = React.lazy(() => import('./containers/Locations'));
const NewAccount = React.lazy(() => import('./containers/Accounts/AddAccount'));
const Setup = React.lazy(() => import('./containers/Setup'));
const Users = React.lazy(() => import('./containers/Users'));
const Login = React.lazy(() => import('./containers/Auth/Login'));
const ResetPassword = React.lazy(() => import('./containers/Auth/ResetPassword'));
const SetupMFA = React.lazy(() => import('./containers/Auth/SetupMFA'));
const MFA = React.lazy(() => import('./containers/Auth/MFA'));
const NewPassword = React.lazy(() => import('./containers/Auth/NewPassword'));
const TermsAndConditions = React.lazy(() => import('./components/Legal/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('./components/Legal/PrivacyPolicy'));
const Legal = React.lazy(() => import('./containers/Legal'));
const AssetDetail = React.lazy(() => import('./containers/AssetDetail/index'));
const ReleaseNotes = React.lazy(() => import('./containers/ReleaseNotes'));
const UserLanding = React.lazy(() => import('./containers/Auth/UserLanding'));

const Loading = () => <div />;

const ErrorMessage = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        align: 'center',
        justifyContent: 'center',
        height: 'calc(100vh)',
      }}
    >
      <h1>Error</h1>
      <h3>
        We couldn't find the page or another unknown error has occurred. It may be helpful to try reloading this page.
      </h3>
    </Container>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Outlet />
        </QueryParamProvider>
      }
    >
      <Route path={routes.privacy} element={<PrivacyPolicy />} />
      <Route path={routes.terms} element={<TermsAndConditions />} />
      <Route element={<AuthLayout />}>
        <Route path={routes.root} element={<Navigate to={routes.dashboard} />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.setupMFA} element={<SetupMFA />} />
        <Route path={routes.MFA} element={<MFA />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.newPassword} element={<NewPassword />} />
        <Route path={routes.userLanding} element={<UserLanding />} />
      </Route>

      <Route path="/" errorElement={<ErrorMessage />} element={<MainLayout />}>
        <Route path={routes.dashboard} element={<Dashboard />} />
        <Route path={routes.assets} element={<Assets />} />
        <Route
          path={`${routes.assets}/:assetId`}
          element={<AssetDetail />}
          errorElement={<ErrorMessage />}
          loader={({ params }) => fetchAssetById(params.assetId)}
        />
        <Route
          path={`${routes.assets}/:assetId/edit`}
          element={<AssetDetailEdit />}
          errorElement={<ErrorMessage />}
          loader={({ params }) => fetchAssetById(params.assetId)}
        />
        <Route
          path={routes.accounts}
          element={
            <React.Suspense fallback={<Loading />}>
              <Accounts />
            </React.Suspense>
          }
        />
        <Route
          path={`${routes.accounts}/add`}
          element={
            <React.Suspense fallback={<Loading />}>
              <NewAccount />
            </React.Suspense>
          }
        />
        <Route path={routes.inventory} element={<Inventory />} />
        <Route path={`${routes.inventory}/:id`} element={<InventoryDetail />} />
        <Route path={`${routes.inventory}/:id/assets`} element={<InventoryDetail />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path={routes.legal} element={<Legal />} />
        <Route path={routes.tutorials} element={<Tutorials />} />
        <Route path={routes.releaseNotes} element={<ReleaseNotes />} />
        <Route path={routes.users} element={<Users />} />
        <Route path={`${routes.users}/add`} element={<AddUser />} />
      </Route>

      <Route path="/" errorElement={<ErrorMessage />} element={<MainLayout oldHeader />}>
        <Route
          path={routes.setup}
          element={
            <React.Suspense fallback={<Loading />}>
              <Setup />
            </React.Suspense>
          }
        />

        <Route
          path={routes.locations}
          element={
            <React.Suspense fallback={<Loading />}>
              <Locations />
            </React.Suspense>
          }
        />
        <Route
          path={routes.docs}
          element={
            <React.Suspense fallback={<Loading />}>
              <Docs />
            </React.Suspense>
          }
        />
      </Route>
    </Route>,
  ),
);

const CreateRoutes = () => {
  return <RouterProvider router={router} />;
};

export default CreateRoutes;
