export const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    redirectSignIn: process.env.REACT_APP_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_SIGN_OUT_URL,
  },
};
