import { Role } from '../types/types';

export const appBarHeight = 130;

export const USER_STORAGE = 'AUTH_USER';
export const TOKEN_STORAGE = 'JWT';
export const LAST_LOGIN_TIMESTAMP = 'last-login-timestamp';

export const InventoryModes = {
  ALL: 'ALL',
  IN_PROGRESS: 'IN_PROGRESS',
  PLANNED: 'PLANNED',
  COMPLETE: 'COMPLETE',
  FINISHED: 'FINISHED',
};

type AllRolesModel = {
  USER: Role;
  VIEWER: Role;
  ADMIN: Role;
  EC: Role;
  GUEST: Role;
}

export const AllRoles: AllRolesModel = {
  USER: {
    name: 'User',
    type: 'USER',
    roleId: 1,
  },
  VIEWER: {
    name: 'Viewer',
    type: 'VIEWER',
    roleId: 2,
  },
  ADMIN: {
    name: 'Admin',
    type: 'ADMIN',
    roleId: 4,
  },
  EC: {
    name: 'EC',
    type: 'EC',
    roleId: 3,
  },
  GUEST: {
    name: 'Guest',
    type: 'GUEST',
    roleId: 5,
  },
};

export const InventoryControlModes = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const DEFAULT_PAGINATION_SIZE = 25;

export const QUERY_KEYS = {
  USER_ACCOUNT: 'user-account',
  ASSETS: 'assets',
  ASSET_SUMMARY: 'asset-summary',
  ALL_USERS: 'all-users',
  INVENTORY_DETAIL: 'inventory-detail',
  USERS: 'users',
  INVENTORIES: 'inventories',
  ASSET_HISTORY: 'asset-history',
  ASSETS_REPORTING: 'assets-reporting',
  TRAINING_VIDEOS_WEB: 'training-videos-web',
  TRAINING_VIDEOS_MOBILE: 'training-videos-mobile',
};
