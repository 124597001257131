import { useState } from 'react';
import Tabs from '../Tabs';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

const Legal = () => {
  const [tab, setTab] = useState('privacy-policy');
  return (
    <>
      <Tabs
        tabs={[
          { label: 'Privacy Policy', value: 'privacy-policy' },
          { label: 'Terms and conditions', value: 'terms-and-conditions' },
        ]}
        selected={tab}
        onChange={setTab}
      />

      <div className="p-3">
        {tab === 'privacy-policy' && <PrivacyPolicy />}

        {tab === 'terms-and-conditions' && <TermsAndConditions />}
      </div>
    </>
  );
};

export default Legal;
