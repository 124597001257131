import { Paginated, TrainingVideo } from '../types/types';
import api from './api';

type SignUpForm = {
  email: string;
  organization: string;
  firstName: string;
  lastName: string;
};

export const signUp = async (payload: SignUpForm) => {
  try {
    await api.post(`/register`, payload);
  } catch (e) {
    console.log('Error in registration: ', e);
  }
};

export const trainingVideos = async (platform: 'web' | 'mobile'): Promise<Paginated<TrainingVideo> | undefined> => {
  try {
    const { data } = await api.get(`/trainingVideos?app=${platform}`);
    return data;
  } catch (e) {
    console.log('Error in training videos: ', e);
  }
};
