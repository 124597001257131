import { ReactComponent as Pilferable } from '../assets/icons/pilferable.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as Missing } from '../assets/icons/missing.svg';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import CheckIcon from '@mui/icons-material/Check';

interface ChipProps {
  variant: 'pilferable' | 'warning' | 'success' | 'danger' | 'missing' | 'neutral';
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  editing?: boolean;
}

// TODO: Improve variants styling
const Chip = ({ variant, text, disabled, onClick, editing }: ChipProps) => {
  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      className={twMerge(
        classNames('flex w-max flex-row items-center gap-1 rounded-full stroke-current px-3 py-2 text-sm font-bold', {
          'bg-chips-pilferable-bg fill-chips-pilferable-fg': variant === 'pilferable',
          'bg-chips-warning-bg fill-chips-warning-fg': variant === 'warning',
          'bg-chips-missing-bg fill-chips-missing-fg': variant === 'missing',
          'bg-success-200': variant === 'success',
          'bg-error-200': variant === 'danger',
          'bg-primary-200': variant === 'neutral',
          'bg-gray-90 fill-gray-200': disabled,
          'cursor-pointer': onClick,
        }),
      )}
    >
      {!editing && variant === 'pilferable' && <Pilferable />}
      {!editing && variant === 'warning' && <Warning />}
      {!editing && variant === 'missing' && <Missing className="text-transparent" />}
      {editing && (
        <div
          className={twMerge(
            classNames('grid h-[18px] w-[18px] place-content-center rounded-sm', {
              'border-2 border-gray-600 bg-gray-90': disabled,
              'bg-chips-pilferable-fg': variant === 'pilferable' && !disabled,
              'bg-chips-warning-fg': variant === 'warning' && !disabled,
              'bg-chips-missing-fg': variant === 'missing' && !disabled
            }),
          )}
        >
          {!disabled && <CheckIcon className={variant === 'missing' ? "text-chips-missing-bg" : "text-white"} sx={{ fontSize: 16 }} />}
        </div>
      )}
      <span
        className={twMerge(
          classNames({
            'text-gray-200': !disabled || !editing,
            'text-gray-600': disabled && editing,
            'text-chips-pilferable-fg': variant === 'pilferable' && !disabled,
            'text-chips-warning-fg': variant === 'warning' && !disabled,
            'text-chips-missing-fg': variant === 'missing' && !disabled, 
            'text-success-500': variant === 'success',
            'text-error-500': variant === 'danger',
            'text-primary-800': variant === 'neutral',
          }),
        )}
      >
        {text}
      </span>
    </div>
  );
};

export default Chip;
