import Chip from '../../../components/Chip';
import { Asset, AssetStatus } from '../../../types/types';

interface AssetStatusChipProps {
  assetStatus?: AssetStatus;
}

const AssetStatusChip = ({ assetStatus }: AssetStatusChipProps) => {
  switch (assetStatus) {
    case 'AVAILABLE':
      return <Chip variant={ 'success' } text={ 'Available' }/>;
    case 'CHECKED_OUT':
      return <Chip variant={ 'neutral' } text={ 'Checked Out' }/>;
    case 'MAINTENANCE':
      return <Chip variant={ 'warning' } text={ 'Maintenance' }/>;
    case 'DECOMMISSIONED':
      return <Chip variant={ 'danger' } text={ 'Decommissioned' }/>;
  }
  return null;
};

export default AssetStatusChip;
