import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#39742c',
      main: '#024800',
      dark: '#002200',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#61f44b',
      main: '#0cc008',
      dark: '#008e00',
      contrastText: '#000000',
    },
    background: {
      default: '#0000',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
