import classNames from 'classnames';
import { ButtonHTMLAttributes, Ref, forwardRef } from 'react';

import Lottie from 'react-lottie';
import spinner from '../assets/lotties/spinner.json';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  square?: boolean;
  loading?: boolean;
  variant?: 'filled' | 'secondary' | 'outlined' | 'text';
  small?: boolean;
  destructive?: boolean;
}

const loadingAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  rendererSettings: {
    scaleMode: 'contain',
  },
};

const Button = forwardRef(
  (
    {
      children,
      className,
      disabled,
      square = false,
      variant = 'filled',
      loading = false,
      small = false,
      destructive = false,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled || loading}
        className={twMerge(
          classNames(
            'relative min-w-[180px] break-words rounded border-2 font-bold transition-opacity',
            {
              '!rounded-none': square,
              'border-transparent bg-primary-500 px-4 py-3 text-white hover:shadow-lg hover:shadow-primary-500/20':
                variant === 'filled',
              'border-transparent bg-primary-200 text-primary-500 transition-shadow hover:shadow-lg hover:shadow-primary-500/20':
                variant === 'secondary',
              'border-primary-500 px-4 py-3 text-primary-500 hover:bg-primary-100/20': variant === 'outlined',
              'mx-auto min-w-0 border-transparent text-primary-500 hover:opacity-70': variant === 'text',
              'hover:opacity-90': !disabled && !loading,
              'hover:text-opacity-90': !disabled && !loading && variant !== 'filled',
              'opacity-60': loading || disabled,
              'min-w-0 px-3 py-1.5 text-sm': small,
              'bg-error-500 text-white hover:shadow-lg hover:shadow-error-500/20': destructive && variant === 'filled',
              'border-transparent bg-error-200 text-error-500 transition-shadow hover:shadow-sm hover:shadow-error-500/40':
                destructive && variant === 'secondary',
              'pointer-events-none': disabled,
              'hover:bg-gray bg-gray-400 text-gray-100 shadow-none': disabled && variant === 'filled',
              'hover:bg-gray bg-gray-100 text-gray-400 shadow-none': disabled && variant === 'secondary',
            },
            className,
          ),
        )}
        {...rest}
      >
        {loading && (
          <Lottie
            options={loadingAnimationOptions}
            style={{
              position: 'absolute',
              display: 'grid',
              placeItems: 'center',
              top: 0,
              left: 0,
            }}
          />
        )}

        {loading ? <div className="opacity-30">{children}</div> : children}
      </button>
    );
  },
);

export default Button;
