import React from "react";
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

export interface ConfirmProps {
  visible: boolean;
  title?: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const Confirm = ({ visible, title, message, onConfirm, onCancel }: ConfirmProps) => {
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MUIButton onClick={onCancel}>Cancel</MUIButton>
        <MUIButton variant="contained" className="!text-white" onClick={onConfirm} autoFocus>
          Continue
        </MUIButton>
      </DialogActions>
    </Dialog>
  )
};
