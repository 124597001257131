import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { UserContextProvider } from './contexts/UserContext';
import Routes from './routes';
import './css/App.css';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { AppContextProvider } from './contexts/AppContext';
import { amplifyConfig } from './config/index';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);
Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig);

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <UserContextProvider>
            <Routes />
          </UserContextProvider>
          <ToastContainer position="bottom-right" />
        </AppContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
