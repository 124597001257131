import { useLoaderData, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

import { Asset } from '../../types/types';
import useAsset from '../../hooks/useAsset';
import { joinStrings } from '../../utils/helpers';

import Button from '../../components/Button';
import Card from '../../components/Card';
import PageScaffold from '../../components/PageScaffold';
import Chip from '../../components/Chip';
import AssetStatusChip from './components/AssetStatusChip';
import TextInput from './components/TextInput';
import { Controller, useForm } from 'react-hook-form';
import { AssetSchema, assetSchema } from '../../validation/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { deleteImage, markAsMissing, updateAsset, updateAssetMaintenance, uploadImage } from '../../api/assets';
import { toast } from 'react-toastify';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import DateInput from "../../components/DateInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Input from "../../components/Input";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Confirm } from "../../components/Confirm";

const AssetDetailEdit = () => {
  const initialAsset = useLoaderData() as Asset;
  const navigate = useNavigate();

  const { asset } = useAsset({ assetId: initialAsset.assetId, initialData: initialAsset });

  const [loading, setLoading] = useState(false);
  const [originalImageDeleted, setOriginalImageDeleted] = useState(false);
  const [newImage, setNewImage] = useState<File | undefined>();

  const formatDate = (date?: string | null) => (date ? dayjs(date).format('MMMM D, YYYY') : undefined);
  
  const numericInputClassNames = "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
    defaultValues: {
      assetId: initialAsset.assetId,
      accountId: initialAsset.account.accountId,
      name: initialAsset.name,
      stockNumber: initialAsset.stockNumber,
      serialNumber: initialAsset.serialNumber,
      barcode: initialAsset.barcode,
      assetType: initialAsset.assetType,
      description: initialAsset.description,
      quantity: initialAsset.quantity,
      acquisitionCost: initialAsset.acquisitionCost,
      acquisitionDate: initialAsset.acquisitionDate,
      building: initialAsset.room.building.name,
      location: initialAsset.room.name,
      pilferable: initialAsset.pilferable ?? 'NO',
      manufacturerName: initialAsset.manufacturerName ?? '',
      manufacturerPartNumber: initialAsset.manufacturerPartNumber ?? '',
      manufacturerModelNumber: initialAsset.manufacturerModelNumber ?? '',
      manufactureYear: initialAsset.manufactureYear,
      externalAssetId: initialAsset.externalAssetId,
      maintenance: initialAsset.maintenance,
      maintenanceNote: initialAsset.maintenanceNote,
      missing: initialAsset.missing,
    },
  });
  
  const [isMissing, setIsMissing] = useState(asset?.missing);
  const [showMissingConfirm, setShowMissingConfirm] = useState(false);
  
  const onSubmit = async (values: AssetSchema) => {
    setLoading(true);

    try {
      await updateAsset(values.assetId, values);
      await updateAssetMaintenance(values.assetId, {
        flag: values.maintenance,
        note: values.maintenanceNote,
      });

      if (originalImageDeleted) {
        await deleteImage(values.assetId);
      } else if (newImage) {
        await uploadImage(values.assetId, newImage);
      }

      toast.success('Asset modified successfully!');
      navigate(-1);
    } catch (error) {
      toast.error('Failed to update asset!');
    } finally {
      setLoading(false);
    }
  };
  
  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeroes and non-numeric characters
    const cleanedValue = e.target.value.replace(/[^0-9]/g, '');

    // Ensure the value is not empty
    if (cleanedValue === '' || cleanedValue === '0') {
      // Handle an empty input
      e.target.value = '';
    } else {
      // Remove leading zeroes
      e.target.value = parseInt(cleanedValue, 10).toString();
    }
  }

  const handleCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    const maxLength = 10;
    const maxDecimals = 2;

    // Remove leading zeroes and non-numeric characters
    let cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
    let [integer, decimal] = cleanedValue.split('.');
    
    // Handle a value that is too long
    if (integer.length >= maxLength) {
      integer = integer.slice(0, maxLength);
    }
    
    // Handle decimals
    if (e.target.value.includes('.')) {
      cleanedValue = `${integer}.${decimal.slice(0, maxDecimals)}`;
    } else {
      cleanedValue = integer ? parseInt(integer, 10).toString() : '';
    }

    e.target.value = cleanedValue;
  }
  
  const onMarkAsMissingClick = async () => {
    setShowMissingConfirm(true);
  };
  
  const onMarkAsMissingCancel = () => {
    setShowMissingConfirm(false);
  }
  
  const onMarkAsMissingConfirm = async () => {
    onMarkAsMissingCancel();
    await markAsMissing(asset!.assetId, !isMissing);
    setIsMissing(!isMissing);
  }

  if (!asset) return null;

  return (
    <PageScaffold
      backTo="Assets"
      backSteps={-2}
      limitWidth
      actions={
        <>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type="submit" form="edit-asset-form" loading={loading}>
            Save
          </Button>
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} id="edit-asset-form">
        <div className="flex flex-col gap-4 pb-4">
          <TextInput
            defaultValue={initialAsset.name}
            error={errors.name?.message}
            {...register('name')}
            className="w-max bg-gray-90 text-left text-2xl font-semibold"
          />

          <div className="lg:grid-rows-auto flex h-auto w-full flex-col place-content-center gap-4 lg:grid lg:grid-cols-11">
            <Card className="lg:col-span-4 lg:row-span-2" title="Asset details" footerComponent={DetailsFooter({
              isMissing, 
              onMarkAsMissingClick, 
              control
            })}>
              <EditImage asset={asset} onOriginalImageDeleted={setOriginalImageDeleted} onNewImage={setNewImage} />

              <div className="flex flex-1 flex-col gap-4 py-4">
                <Card.Property label="Stock Number">
                  <TextInput
                    defaultValue={initialAsset.stockNumber}
                    error={errors.stockNumber?.message}
                    {...register('stockNumber')}
                  />
                </Card.Property>
                <Card.Property label="Serial Number">
                  <TextInput
                    defaultValue={initialAsset.serialNumber}
                    error={errors.serialNumber?.message}
                    {...register('serialNumber')}
                  />
                </Card.Property>
                <Card.Property label="Barcode">
                  <TextInput
                    defaultValue={initialAsset.barcode}
                    error={errors.barcode?.message}
                    {...register('barcode')}
                  />
                </Card.Property>
                <Card.Property label="Product Type">
                  <TextInput
                    defaultValue={initialAsset.assetType}
                    error={errors.assetType?.message}
                    {...register('assetType')}
                  />
                </Card.Property>
              </div>
            </Card>

            <Card title="Status" className="lg:col-span-4" headerComponent={<AssetStatusChip assetStatus={asset.status} />}>
              <div className="flex flex-col gap-4 py-4">
                <Card.Property label="Checked Out" value={formatDate(asset.checkoutAsset?.checkout.checkoutDate)} />
                <Card.Property label="Check Out Status">
                  {asset.checkoutStatus === 'OVERDUE' && (
                    <span className="break-all	text-end text-sm text-error-500">
                      Overdue
                    </span>
                  )}
                </Card.Property>
                <Card.Property label="Due Date" value={formatDate(asset.checkoutAsset?.checkout.returnByDate)} />
                <Card.Property
                  label="Current Holder"
                  value={joinStrings([
                    asset.checkoutAsset?.checkout.issuedTo.firstName,
                    asset.checkoutAsset?.checkout.issuedTo.lastName,
                  ])}
                />
                <Card.Property label="Last Scanned" value={formatDate(asset.lastScanDate)} />
              </div>
            </Card>

            <Card title="Asset note" className="lg:col-span-3">
              <textarea
                className="rounded border border-gray-100 bg-gray-80 px-2 py-1"
                rows={8}
                defaultValue={initialAsset.maintenanceNote ?? ''}
                {...register('maintenanceNote')}
              />
            </Card>

            <Card title="Purchase information" className="lg:col-span-4">
              <div className="flex flex-col gap-4 py-4">
                <Card.Property label="Acquisition Cost">
                  <div
                    className="relative inline before:absolute before:content-['$'] before:left-4 before:top-1">
                    <TextInput
                      type="text"
                      inputMode="numeric"
                      defaultValue={initialAsset.acquisitionCost}
                      error={errors.acquisitionCost?.message}
                      className={numericInputClassNames}
                      {...register('acquisitionCost', {
                        onChange: handleCostChange,
                        setValueAs: (value) => (!value ? undefined : parseFloat(value)),
                      })}
                    />
                  </div>
                </Card.Property>
                <Card.Property label="Acquisition Date">
                  <div className="flex flex-col gap-1">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                      <Controller
                        name="acquisitionDate"
                        control={control}
                        defaultValue={initialAsset.acquisitionDate ?? new Date().toString()}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            value={value}
                            onChange={(newValue) => {
                              const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                              onChange(newValue && formattedDate ? formattedDate : null);
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                              <div className="relative">
                                <input
                                  type="date"
                                  ref={inputRef}
                                  {...inputProps}
                                  className={twMerge(classNames(
                                    'rounded border border-gray-100 bg-gray-80 px-2 py-1 text-right pr-10 w-full',
                                    {
                                      'border-red-500': !!errors.acquisitionDate?.message,
                                    }))}
                                />
                                <div className="absolute right-3 top-1/2">
                                  {InputProps?.endAdornment}
                                </div>
                              </div>
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors.acquisitionDate?.message && <div className="text-xs text-red-500">{errors.acquisitionDate?.message}</div>}
                  </div>
                </Card.Property>
                <Card.Property label="Manufacturer Name">
                  <TextInput
                    defaultValue={initialAsset.manufacturerName}
                    error={errors.manufacturerName?.message}
                    {...register('manufacturerName')}
                  />
                </Card.Property>
                <Card.Property label="Manufacturer Part Number">
                  <TextInput
                    defaultValue={initialAsset.manufacturerPartNumber}
                    error={errors.manufacturerPartNumber?.message}
                    {...register('manufacturerPartNumber')}
                  />
                </Card.Property>
                <Card.Property label="Manufacturer Model Number">
                  <TextInput
                    defaultValue={initialAsset.manufacturerModelNumber}
                    error={errors.manufacturerModelNumber?.message}
                    {...register('manufacturerModelNumber')}
                  />
                </Card.Property>
                <Card.Property label="Manufacture Year">
                  <TextInput
                    maxLength={4}
                    defaultValue={initialAsset.manufactureYear}
                    error={errors.manufactureYear?.message}
                    className={numericInputClassNames}
                    {...register('manufactureYear', {
                      setValueAs: (value) => (!value || value === '' ? undefined : parseInt(value)),
                      onChange: handleYearChange
                    })}
                  />
                </Card.Property>
              </div>
            </Card>

            <Card title="Import information" className="lg:col-span-3">
              <div className="flex flex-col gap-4 py-4">
                <Card.Property
                  label="Import User"
                  value={joinStrings([asset.importedBy?.firstName, asset.importedBy?.lastName])}
                />
                <Card.Property label="Import Date" value={formatDate(asset.importDatetime)} />
                <Card.Property label="Import File Name" value={`${asset.importFileName}`} />
              </div>
            </Card>
          </div>
        </div>
      </form>
      <Confirm 
        visible={showMissingConfirm} 
        onConfirm={onMarkAsMissingConfirm} 
        onCancel={onMarkAsMissingCancel}
        title={isMissing ?
          'Mark Asset as Not Missing' :
          'Mark Asset as Missing'}
        message={isMissing ?
          'Are you sure you want to mark this asset as not missing?' :
          'Are you sure you want to mark this asset as missing?'}
      />
    </PageScaffold>
  );
};

interface EditImageProps {
  asset: Asset;
  onOriginalImageDeleted: (deleted: boolean) => void;
  onNewImage: (newImage: File | undefined) => void;
}

const EditImage = ({ asset, onOriginalImageDeleted, onNewImage }: EditImageProps) => {
  const imageRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [preview, setPreview] = useState<string | undefined>();
  const [originalImageDeleted, setOriginalImageDeleted] = useState(false);

  const imagePreviewSrc = useMemo(() => {
    if (preview) {
      return preview;
    }
    if (asset.hasPicture && !originalImageDeleted) {
      return asset.pictureMediumURL;
    }
    return undefined;
  }, [asset.hasPicture, asset.pictureMediumURL, originalImageDeleted, preview]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    onOriginalImageDeleted(originalImageDeleted);
  }, [onOriginalImageDeleted, originalImageDeleted]);

  useEffect(() => {
    onNewImage(selectedFile);
  }, [onNewImage, selectedFile]);

  return (
    <div className="flex flex-col">
      <div className="relative aspect-video h-full w-full overflow-clip rounded-xl">
        {imagePreviewSrc ? (
          <div className="relative aspect-video overflow-clip rounded-xl">
            <img src={imagePreviewSrc} alt={asset.name} draggable={false} className="aspect-video object-contain" />
          </div>
        ) : (
          <div className="grid aspect-video place-content-center rounded-xl bg-gray-90">
            <ImageNotSupportedIcon color="disabled" />
          </div>
        )}
      </div>

      <div className="mt-5 flex gap-5 self-end">
        <input type="file" className="hidden" onChange={(e) => handleImageChange(e)} ref={imageRef} multiple={false} />
        {originalImageDeleted || selectedFile ? (
          <Button
            variant="text"
            type="button"
            onClick={() => {
              setOriginalImageDeleted(false);
              setSelectedFile(undefined);
              setPreview(undefined);
            }}
          >
            Cancel
          </Button>
        ) : (
          <>
            <Button
              variant="text"
              type="button"
              onClick={() => {
                imageRef.current?.click();
              }}
            >
              Upload
            </Button>
            {asset.hasPicture && (
              <Button
                variant="text"
                type="button"
                className="text-red-500"
                onClick={() => setOriginalImageDeleted(true)}
              >
                Remove
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};



const DetailsFooter = ({
  isMissing,
  onMarkAsMissingClick,
  control,
}: {
  isMissing?: boolean;
  onMarkAsMissingClick: () => Promise<void>;
  control: any;
}) => {
  return (
    <div className="mt-auto bg-gray-90">
      <span className="block mb-4 text-sm font-bold text-primary-800">Asset flags</span>
      <div className="flex flex-row flex-wrap justify-end gap-3">
        <Chip
          editing
          variant="missing"
          text="Missing"
          disabled={!isMissing}
          onClick={onMarkAsMissingClick}
        />
        <Controller
          name="pilferable"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Chip
              editing
              variant="pilferable"
              text="Pilferable"
              disabled={value !== 'YES'}
              onClick={() => {
                onChange(value === 'YES' ? 'NO' : 'YES');
              }}
            />
          )}
        />
        <Controller
          name="maintenance"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Chip
              editing
              variant="warning"
              text="Maintenance"
              disabled={!value}
              onClick={() => {
                onChange(!value);
              }}
            />
          )}
        />
      </div>
    </div>
  )
}

export default AssetDetailEdit;
